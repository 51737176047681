import api from "@/services/api";
const base = "/products";

const Product = {
  index: (params: { page?: number; pageSize?: number, search?: string; categoryId?: number, categoryName?: string, active?: boolean } = {}) => {
    const query = new URLSearchParams();

    console.log("params",params)

    if (params.page) query.append("page", String(params.page));
    if (params.pageSize) query.append("pageSize", String(params.pageSize));
    if (params.search) query.append("search", params.search);
    if (params.categoryId) query.append("categoryId", String(params.categoryId));
    if (params.categoryName) query.append("categoryName", String(params.categoryName));
    if (params.active) query.append("active", String(params.active));

    return api(
      "GET",
      `${base}/paginate?${query.toString()}`,
      "",
      `getting products list with filters`
    );
  },
  update: (payload: any, id: string) =>
    api("PUT", `${base}/${id}`, payload, `updating products`),
  delete: (id: any) => api("DEL", `${base}/${id}`, "", `deleting products`),
  create: (payload: any) =>
    api("POST", `${base}`, payload, `creating products`),
  getProduct: (id: string) =>
    api("GET", `${base}/${id}`, "", `getting single product`),
};

export default Product;
