"use client";

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import NavItem from "@/shared/NavItem/NavItem";
import Category from "@/models/Category";
import { FC } from "react";

export interface CategoryFilterProps {
  updateProducts: (categoryId: number) => any;
}

const CategoryFilter: FC<CategoryFilterProps> = ({ updateProducts }) => {
  const [tabActive, setTabActive] = useState("All items");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleSelectedCategory = (category: { id: number; name: string }) => {
    setTabActive(category.name); // Mettre à jour l'état actif
    updateProducts(category.id);
    setDropdownOpen(false); // Fermer le dropdown après sélection
  };

  const fetchCategories = async () => {
    const response = await Category.index();
    return [{ name: "All items", id: 0 }, ...response.data];
  };

  const {
    data: categories = [],
    isLoading: isCategorieLoading,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
  });

  return (
    <div className="relative w-full">
      {/* Barre principale */}
      <div className="flex items-center justify-between">
        <div className="flex overflow-x-auto space-x-4 hiddenScrollbar">
          {!isCategorieLoading &&
            categories.slice(0, 5).map((item, index) => (
              <NavItem
                key={index}
                isActive={tabActive === item.name}
                onClick={() => handleSelectedCategory(item)}
                className={`px-4 py-2 rounded-md cursor-pointer ${
                  tabActive === item.name
                    ? "bg-primary-100 text-primary-700 dark:bg-primary-700 dark:text-white"
                    : "bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-gray-300"
                }`}
              >
                {item.name}
              </NavItem>
            ))}
        </div>
        {/* Bouton pour le dropdown */}
        {categories.length > 5 && (
          <button
            onClick={() => setDropdownOpen(!isDropdownOpen)}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-md text-sm"
          >
            Plus
          </button>
        )}
      </div>

      {/* Dropdown des catégories supplémentaires */}
      {isDropdownOpen && (
        <div className="absolute z-10 mt-2 bg-white dark:bg-gray-800 shadow-lg rounded-lg w-full max-h-64 overflow-y-auto hiddenScrollbar">
          {!isCategorieLoading &&
            categories.slice(5).map((item, index) => (
              <div
                key={index}
                onClick={() => handleSelectedCategory(item)}
                className={`px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer ${
                  tabActive === item.name
                    ? "bg-primary-100 text-primary-600 dark:bg-primary-600 dark:text-white"
                    : "text-gray-700 dark:text-gray-300"
                }`}
              >
                {item.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
