"use client";

import Link from "next/link";
import NcImage from "@/shared/NcImage/NcImage";
import React, { FC, useState } from "react";
import { setImage } from "@/utils";

export interface ProductCardProps {
  data?: any;
  className?: string;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
}: ProductCardProps) => {
  return (
    <div
      className={`nc-ProductCard relative flex flex-col pb-4 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow 
        divide-y overflow-hidden ${className}`}
    >
      {/* Lien vers le produit */}
      <Link href={`/product/${data?.id}`} className="absolute inset-0"></Link>

      {/* Conteneur d'image */}
      <div className="relative h-52 bg-slate-50 dark:bg-slate-300 overflow-hidden z-1 max-h-52">
        <Link href={`/product/${data?.id}`} className="block h-full">
          <NcImage
            fill
            alt={data?.name || "product"}
            src={setImage(data?.images[0]?.imageUrl)}
            className="object-contain w-full h-full transition-transform hover:scale-105"
            containerClassName=""
            sizes="(max-width: 640px) 100vw, (max-width: 1200px) 50vw, 20vw"
          />
        </Link>
      </div>

      {/* Informations sur le produit */}
      <div className="p-4">
        <div className="space-y-2">
          {/* Titre du produit */}
          <h2 className="text-base font-semibold line-clamp-1 transition-colors capitalize hover:text-primary-500">
            {data?.name}
          </h2>
          {/* Description */}
          <p className="text-sm text-slate-500 dark:text-slate-400 line-clamp-2 lowercase">
            {data?.description}
          </p>
        </div>

        {/* Prix et informations supplémentaires */}
        <div className="flex justify-between items-center mt-4">
          {/* Prix stylisé */}
          <div className="flex items-center space-x-2">
            <div className="relative flex items-center space-x-1 bg-gradient-to-r from-green-500 to-green-700 text-white font-bold rounded-full px-4 py-2 shadow">
              <span className="text-xl font-extrabold">{data?.price.toLocaleString()}</span>
              <span className="text-sm font-medium">FCFA</span>
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-white rounded-full animate-ping"></div>
            </div>
          </div>

          {/* Information importante */}
          <div className="flex flex-col items-end text-right">
            {/* Gestion du stock */}
            {/* {data?.stock > 10 ? (
              <span className="text-sm font-semibold text-green-600">En stock</span>
            ) : data?.stock > 0 ? (
              <span className="text-sm font-semibold text-orange-600">
                Hâtez-vous, {data.stock} restants !
              </span>
            ) : (
              <span className="text-sm font-semibold text-red-500">Rupture</span>
            )} */}

            {/* Livraison estimée */}
            {/* <span className="text-xs text-slate-500 dark:text-slate-400">
              Livré avant le{" "}
              {new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
